export enum Route {
  LOGIN = 'login',
  DIRECT_LOGIN = 'login-direct',
  SIGNUP = 'signup',
  REGISTER_LOCK = 'reg-lock',
  REGISTRATION_FAIL = 'reg-fail',
  REGISTER_IP_LOCK = 'reg-ip-lock',
  PASSWORD_RESET = 'password-reset',
  PASSWORD_RECOVERY = 'password-recovery',
  RECOVERY_FAILURE = 'recovery-failure',
  NEW_ACCOUNT = 'new-account',
  DASHBOARD_SCORE = 'dashboard',
  DASHBOARD_SCORE_UP = 'dashboard/stitch_up',
  DASHBOARD_REPORT = 'dashboard/report',
  DASHBOARD_MONITOR = 'dashboard/monitor',
  DASHBOARD_IDRESTORE = 'dashboard/restoration',
  SETTINGS = 'settings',
  ABOUT = 'about',
  IDENTITY = 'identity',
  CLOSE_ACCOUNT = 'close-account',
  ACCOUNT_CLOSED = 'account-closed',
  MOBILE = 'mobile',
  DIT = 'dit',
  EID = 'eid',
  EID_FAILED = 'eid-failed',
  ID_SUCCESS_EID_FAILED = 'id-success-eid-failed',
  ID_QUIZ = 'id-quiz',
  ID_SUCCESS = 'id-success',
  ID_RESTORE = 'id-restore',
  NOT_FOUND = 'not-found',
  THIN_FILE = 'thin_file',
  SERVICE_FAILURE = 'service_failure',
  SYS_ERROR = 'sys-error',
  ACCOUNT_LOCK_TEMP = 'account-lock-temp',
  ACCOUNT_LOCK_PERM = 'account-lock-perm',
  MENU = 'menu',
  USER_NOT_FOUND = 'user-not-found',
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  SCORE_FACTORY = 'score-factors',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_EMAIL = 'change-email',
  CHANGE_RECOVERY_QUESTION = 'change-recovery-question',
  LOGIN_TILE = 'login-tile',
  LOGIN_AIO = 'login-aio',
  ALL_IN_ONE = 'all-in-one',
  ALERT_NOTIFICATIONS = 'alert-notifications',
  TILE_VIEW = 'tile-view'
}
