import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { useStores } from '@utils/hooks/useStores';

import Score from '@components/tileComponents/Score';
import Alerts from '@components/tileComponents/Alerts';
import Reports from '@components/tileComponents/Reports';
import { checkFlag } from '@utils/helpers/checkPermission';
import ScoreHistory from '@components/tileComponents/ScoreHistory';

import colors from '@assets/styles/colors';
import fontSizes from '@assets/styles/fontSizes';
import { createStyles, makeStyles } from '@material-ui/core';

import Flags from '@utils/constants/flags';
import ILatestCreditScore from '@interfaces/latestCreditScore';
import PageSwipeHeader from '@components/PageSwipeHeader';
import ScoreUp from '@components/ScoreUp';

interface IProps {}

const useStyles = makeStyles(theme =>
  createStyles({
    wrapper: {
      display: 'flex',
      borderRadius: 4,
      margin: '0 auto',
      background: 'white',
      flexDirection: 'column'
    },
    menuItem: {
      cursor: 'pointer'
    },
    active: {
      color: colors.lightPink
    },
    loading: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: colors.lightInputText
    },
    list: {
      margin: '30px 15px',
      padding: 0,
      color: colors.violet,
      '& > li': {
        marginBottom: 20
      },
      '& > li span': {
        color: colors.lightBlack,
        fontWeight: 300
      }
    },
    bold: {
      fontWeight: 'bold'
    },
    hideMenu: {
      height: 0,
      padding: '0 !important',
      visibility: 'hidden'
    }
  })
);

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 700px;
`;

const Content = styled.div<{ direction?: string }>`
  flex-grow: 1;
  display: flex;
  padding: 20px 30px;
  align-items: center;
  flex-direction: ${({ direction }) => (direction ? direction : 'column')};
`;

const Menu = styled.div`
  display: flex;
  padding: 30px 20px;
  align-items: center;
  justify-content: space-between;
  font-size: ${fontSizes.paragraph1};
  border-top: 1px solid ${colors.gray};
`;

const RestoreWrapper = styled.div`
  text-align: left;
  position: relative;
  font-size: ${fontSizes.paragraph1};
`;

const IdRestore: React.FC<{}> = () => {
  const classes = useStyles();
  return (
    <RestoreWrapper>
      <p>Equifax® ID Restoration</p>
      <div>
        <p>
          <b>Has your identity been compromised?</b>
        </p>
        <ul className={classes.list}>
          <li>
            <span>A specialist will help you work through the process.</span>
          </li>
          <li>
            <span>
              Once your issues are resolved, we continue to monitor the
              situation for three months.
            </span>
          </li>
          <li>
            <span>
              Call today so that we may help you verify and begin resolving any
              discrepancies.
            </span>
          </li>
        </ul>
      </div>
      <div>
        <p className={classes.bold}>
          Call 1-877-368-4940 today to get started <br />
          {/*or <Link href="#">click here</Link> to learn more.*/}
        </p>
      </div>
    </RestoreWrapper>
  );
};

const TileView: React.FC<IProps> = () => {
  const classes = useStyles();
  const { appStore, dashboardStore } = useStores();
  const [showModule, setShowModule] = useState<string>();
  const menuRef = useRef(null);
  const [hideMenu, setHideMenu] = useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [pages, setPages] = useState<string[]>([]);

  const handleModuleChange = (module: string) => {
    if (showModule !== module) setShowModule(module);
  };

  const renderContent = () => {
    switch (showModule) {
      case 'score':
        return <Score activeStep={activeStep} />;
      case 'scoreUp':
        return <ScoreUp />;
      case 'scoreHistory':
        return <ScoreHistory activeStep={activeStep} />;
      case 'reports':
        return <Reports activeStep={activeStep} />;
      case 'alerts':
        return <Alerts />;
      case 'idRestore':
        return <IdRestore />;

      default:
        return <></>;
    }
  };

  useEffect(() => {
    dashboardStore.getLatestScore();
    dashboardStore.getScoreUp();
  }, [dashboardStore]);

  useEffect(() => {
    if (
      dashboardStore.latestScore &&
      dashboardStore.latestScore.providerViews
    ) {
      setPages(
        dashboardStore.latestScore.providerViews
          // @ts-ignore
          .map((d: ILatestCreditScore) => d.provider)
          .filter(
            (item: ILatestCreditScore, i: number, ar: any) =>
              ar.indexOf(item) === i
          )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardStore.latestScore, pages.length, activeStep]);

  useEffect(() => {
    // @ts-ignore
    if (menuRef && menuRef.current && menuRef.current.children.length > 0) {
      // @ts-ignore
      menuRef.current.children[0].click();
      // @ts-ignore
      if (menuRef.current.children.length === 1) setHideMenu(true);
    }
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Wrapper className={classes.wrapper}>
        <PageSwipeHeader
          style={{ marginTop: 0, background: '#eeeeee' }}
          back={handleBack}
          next={handleNext}
          activeIndex={activeStep}
          pages={pages.length}
          title={pages[activeStep]}
        />
        <Content direction={showModule === 'reports' ? 'column' : 'row'}>
          {renderContent()}
        </Content>
        <Menu ref={menuRef} className={hideMenu ? classes.hideMenu : ''}>
          {checkFlag(appStore.getConfig().flags, Flags.SCORE) && (
            <span
              onClick={() => {
                handleModuleChange('score');
              }}
              className={`${showModule === 'score' ? classes.active : ''} ${
                classes.menuItem
              }`}
            >
              Score
            </span>
          )}

          {checkFlag(appStore.getConfig().flags, Flags.SCORE_UP) && (
            <span
              onClick={() => {
                handleModuleChange('scoreUp');
              }}
              className={`${showModule === 'scoreUp' ? classes.active : ''} ${
                classes.menuItem
              }`}
            >
              Score Horizon
            </span>
          )}

          {checkFlag(appStore.getConfig().flags, Flags.SCORE_HISTORY) && (
            <span
              onClick={() => {
                handleModuleChange('scoreHistory');
              }}
              className={`${
                showModule === 'scoreHistory' ? classes.active : ''
              } ${classes.menuItem}`}
            >
              Score history
            </span>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.REPORT) && (
            <span
              onClick={() => {
                handleModuleChange('reports');
              }}
              className={`${showModule === 'reports' ? classes.active : ''} ${
                classes.menuItem
              }`}
            >
              Reports
            </span>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.ALERTS) && (
            <span
              onClick={() => {
                handleModuleChange('alerts');
              }}
              className={`${showModule === 'alerts' ? classes.active : ''} ${
                classes.menuItem
              }`}
            >
              Alerts
            </span>
          )}
          {checkFlag(appStore.getConfig().flags, Flags.RESTORE) && (
            <span
              onClick={() => {
                handleModuleChange('idRestore');
              }}
              className={`${showModule === 'idRestore' ? classes.active : ''} ${
                classes.menuItem
              }`}
            >
              ID Restore
            </span>
          )}
        </Menu>
      </Wrapper>
    </>
  );
};

export default observer(TileView);
